import { addDays, lightFormat } from 'date-fns';
import { PaymentTermsType } from '../models/invoiceModel';

export const daysPerMonth = new Map([
  [0, 31],
  [1, 28],
  [2, 31],
  [3, 30],
  [4, 31],
  [5, 30],
  [6, 31],
  [7, 31],
  [8, 30],
  [9, 31],
  [10, 30],
  [11, 31]
]);

export const months = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre"
];

export function formatDateIT(date: Date | string): string {
  return lightFormat(new Date(date), 'dd/MM/yyyy');
}

export function formatDateUS(date: Date | string): string {
  return lightFormat(new Date(date), 'yyyy-MM-dd');
}

export function formatDateITWithHour(date: Date | string): string {
  return lightFormat(new Date(date), "dd/MM/yyyy', 'HH:mm");
}

export function checkLeapYear(year: number): boolean {
  return new Date(year, 1, 29).getDate() === 29;
}

export function getDateFromPaymentTerms(startDate: Date, terms?: PaymentTermsType): Date | undefined {
  if (terms === PaymentTermsType.CUSTOMIZED) {
    return undefined;
  }
  if (terms === PaymentTermsType.IMMEDIATE) {
    return new Date(startDate);
  }
  if (terms === PaymentTermsType.DF30) {
    return addDays(new Date(startDate), 30);
  }
  if (terms === PaymentTermsType.DF60) {
    return addDays(new Date(startDate), 60);
  }
  if (terms === PaymentTermsType.DF90) {
    return addDays(new Date(startDate), 90);
  }
  if (terms === PaymentTermsType.FM30) {
    const date = addDays(new Date(startDate), 30);
    return dateAtTheEndOfTheMonth(date);
  }
  if (terms === PaymentTermsType.FM60) {
    const date = addDays(new Date(startDate), 60);
    return dateAtTheEndOfTheMonth(date);
  }
  if (terms === PaymentTermsType.FM90) {
    const date = addDays(new Date(startDate), 90);
    return dateAtTheEndOfTheMonth(date);
  } else return undefined;
}

export function dateAtTheEndOfTheMonth(date: Date): Date | undefined {
  if (checkLeapYear(date.getFullYear())) {
    daysPerMonth.set(1, 29)
  }

  const lastDay = daysPerMonth.get(date.getMonth());

  if (!lastDay) {
    return undefined;
  }

  const newDate = new Date(date);
  newDate.setDate(date.getDate() + (lastDay - date.getDate()));

  return newDate;
}

export function getDateRangeByYearAndMonth(
  year: string,
  month?: string
): {
  dateFrom: string;
  dateTo: string;
} {
  let dateFrom = year + '-01-01';
  let dateTo = year + '-12-31';

  if (
    month !== undefined &&
    month !== ''
  ) {
    dateFrom = year + '-' + month + '-01';
    dateTo = year + '-' + month + '-' + String(getLastDayOfMonth(Number(month)));
  }

  return {
    dateFrom,
    dateTo
  };
}

export function getLastDayOfMonth(month: number): number | undefined {
  return daysPerMonth.get(month - 1);
}

export function toDate(value?: string): Date | undefined {
  let date: Date | undefined = undefined;

  if (value !== undefined && value !== '') {
    date = new Date(value);
  }

  return date;
}

export function resolveUndefinedDate(value?: string): Date {
  let date: Date = new Date();

  if (value !== undefined && value !== '') {
    date = new Date(value);
  }

  return date;
}

export function removeTime(value: Date | string): Date {
  const date = new Date(value);

  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function generateArrayOfYears(futureDate?: boolean) {
  let max = futureDate === true ? new Date(2044, 0, 1).getFullYear() : new Date().getFullYear() +1;
  let min = max - 80;
  let years = [];

  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}