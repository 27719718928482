import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactModel, ContactType } from '../../models/contactModel';
import {
  AdditionalDataPAModel,
  AdditionalDataPAType,
  CurrencyType,
  GoodServicesModel,
  InvoiceModel,
  InvoiceStatusType,
  InvoiceType,
  PaymentModeType,
  PaymentTermsType,
  ReimbursementOfExpenseModel,
  TypeWithholding,
  Withholding,
  WithholdingCausal,
} from '../../models/invoiceModel';
import { CustomerInvoiceService } from '../../services/customerInvoiceService';
import { PromiseStatuses } from '../../types/strings';
import { BankAccount } from '../../models/userModel';

interface CustomerInvoiceState {
  findByIdStatus: PromiseStatuses;
  fileList: File[];
  reimbursementsOfExpenses: ReimbursementOfExpenseModel[];
  invoice: InvoiceModel;
  downloadExcelResponse?: ArrayBuffer;
}

const initialState: CustomerInvoiceState = {
  findByIdStatus: 'idle',
  fileList: [],
  reimbursementsOfExpenses: [],
  invoice: {
    date: new Date(),
    type: InvoiceType.TD01,
    status: InvoiceStatusType.DRAFT,
    currency: CurrencyType.EUR,
    stamp: false,
    amount: 0,
    taxBase: 0,
    goodServices: [],
    socialContribution: false,
    socialContributionAmount: 0,
    reimbursementsOfExpenses: [],
    reimbursementsOfExpensesAmount: 0,
    manual: false,
    isPaymentDue: false,
    trackedPayment: false,
    customerDataCommunication: false,
    payments: [],
    documents: [],
    additionalDatasPa: [],
    withholding: {
      active: false,
      causal: WithholdingCausal.ZO,
      type: TypeWithholding.RT03,
      amount: 0.0,
      rate: 100,
    },
    supplier: {
      bankAccount: { bankIban: '', bankName: '' },
      type: ContactType.CUSTOMER,
    },
  },
};

export const findCustomerInvoiceById = createAsyncThunk(
  'customerInvoice/findCustomerInvoiceById',
  async (
    invoiceId: string
  ): Promise<{
    invoice: InvoiceModel;
    files: File[];
    reimbursementsOfExpenses: ReimbursementOfExpenseModel[];
  }> => {
    const service = new CustomerInvoiceService();

    return service.findById(invoiceId);
  }
);

const customerInvoiceSlice = createSlice({
  name: 'customerInvoice',
  initialState,
  reducers: {
    setCustomerInvoiceFileList: (state, action: PayloadAction<File[]>) => {
      state.fileList = action.payload;
    },
    setCustomerInvoiceReimbursementsOfExpenses: (
      state,
      action: PayloadAction<ReimbursementOfExpenseModel[]>
    ) => {
      state.reimbursementsOfExpenses = action.payload;
    },
    setCustomerInvoiceCustomer: (
      state,
      action: PayloadAction<ContactModel | undefined>
    ) => {
      state.invoice.customer = action.payload;
    },
    setCustomerInvoiceStatus: (
      state,
      action: PayloadAction<InvoiceStatusType>
    ) => {
      state.invoice.status = action.payload;
    },
    setCustomerInvoicePreviousStatus: (
      state,
      action: PayloadAction<InvoiceStatusType | undefined>
    ) => {
      state.invoice.previousStatus = action.payload;
    },
    setCustomerInvoiceExternalID: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.invoice.externalID = action.payload;
    },
    setCustomerInvoiceAteco: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.invoice.ateco = action.payload;
    },
    setCustomerInvoiceBankAccount: (
      state,
      action: PayloadAction<BankAccount>
    ) => {
      if (state.invoice.supplier !== undefined) {
        state.invoice.supplier.bankAccount.bankName = action.payload.name;
        state.invoice.supplier.bankAccount.bankIban = action.payload.iban;
      }
    },
    setCustomerInvoiceSocialContribution: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.invoice.socialContribution = action.payload;
    },
    setCustomerInvoiceFund: (state, action: PayloadAction<string>) => {
      state.invoice.fund = action.payload;
    },
    setCustomerInvoiceSocialContributionAmount: (
      state,
      action: PayloadAction<number>
    ) => {
      state.invoice.socialContributionAmount = action.payload;
    },
    setCustomerInvoiceDate: (state, action: PayloadAction<Date>) => {
      state.invoice.date = action.payload;
    },
    setCustomerInvoiceType: (
      state,
      action: PayloadAction<InvoiceType | undefined>
    ) => {
      state.invoice.type = action.payload;
    },
    setCustomerInvoicePaymentMode: (
      state,
      action: PayloadAction<PaymentModeType | undefined>
    ) => {
      state.invoice.paymentMode = action.payload;
    },
    setCustomerInvoicePaymentExpiration: (
      state,
      action: PayloadAction<Date | undefined>
    ) => {
      state.invoice.paymentExpiration = action.payload;
    },
    setCustomerInvoicePaymentTerms: (
      state,
      action: PayloadAction<PaymentTermsType | undefined>
    ) => {
      state.invoice.paymentTerms = action.payload;
    },
    setCustomerInvoiceNotes: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.invoice.notes = action.payload;
    },
    setCustomerInvoiceCausal: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.invoice.causal = action.payload;
    },
    setCustomerInvoiceWithholding: (
      state,
      action: PayloadAction<Withholding | undefined>
    ) => {
      state.invoice.withholding = action.payload;
    },
    setCustomerInvoiceWithholdingRate: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      if (state.invoice.withholding) {
        state.invoice.withholding.rate = action.payload;
      }
    },
    setCustomerInvoiceWithholdingActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      if (action.payload === true) {
        state.invoice.withholding = {
          active: true,
          amount: 0.0,
          rate: 100.0,
          causal: WithholdingCausal.ZO,
          type:
            state.invoice.fund === 'TC07'
              ? TypeWithholding.RT04
              : TypeWithholding.RT03,
        };
      } else {
        state.invoice.withholding = null;
      }
    },
    setCustomerInvoiceWithholdingAmount: (
      state,
      action: PayloadAction<number>
    ) => {
      if (state.invoice.withholding) {
        state.invoice.withholding.amount = action.payload;
      }
    },
    setCustomerInvoiceWithholdingType: (
      state,
      action: PayloadAction<TypeWithholding>
    ) => {
      if (state.invoice.withholding) {
        state.invoice.withholding.type = action.payload;
      }
    },
    setCustomerInvoiceWithholdingCausal: (
      state,
      action: PayloadAction<WithholdingCausal>
    ) => {
      if (state.invoice.withholding) {
        state.invoice.withholding.causal = action.payload;
      }
    },
    setCustomerInvoiceWithholdingTaxRate: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      if (state.invoice.withholding) {
        state.invoice.withholding.taxRate = action.payload;
      }
    },
    setCustomerInvoiceNumerationCustom: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.invoice.customNumeration = action.payload;
    },
    setCustomerInvoiceGoodServices: (
      state,
      action: PayloadAction<GoodServicesModel[]>
    ) => {
      state.invoice.goodServices = action.payload;
    },
    setCustomerInvoiceStamp: (state, action: PayloadAction<boolean>) => {
      state.invoice.stamp = action.payload;
    },
    setCustomerInvoiceReimbursementOfExpensesAmount: (
      state,
      action: PayloadAction<number>
    ) => {
      state.invoice.reimbursementsOfExpensesAmount = action.payload;
    },
    setCustomerInvoiceTaxBase: (state, action: PayloadAction<number>) => {
      state.invoice.taxBase = action.payload;
    },
    setCustomerInvoice: (state, action: PayloadAction<InvoiceModel>) => {
      state.invoice = action.payload;
    },
    setCustomerInvoiceNumber: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.invoice.number = action.payload;
    },
    setCustomerInvoiceDocumentType: (
      state,
      action: PayloadAction<InvoiceType | undefined>
    ) => {
      state.invoice.type = action.payload;
    },
    setCustomerInvoiceSocialEnasarco: (
      state,
      action: PayloadAction<number>
    ) => {
      state.invoice.enasarcoSocialContribution = action.payload;
    },
    setCustomerInvoicePdfMailLanguage: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.invoice.language = action.payload;
    },
    setCustomerInvoiceAdditionalDatasPa: (
      state,
      action: PayloadAction<AdditionalDataPAModel[] | null>
    ) => {
      state.invoice.additionalDatasPa = action.payload;
    },
    setCustomerInvoiceAdditionalDataPAType: (
      state,
      action: PayloadAction<AdditionalDataPAType | undefined>
    ) => {
      if (
        state.invoice.additionalDatasPa !== undefined &&
        state.invoice.additionalDatasPa !== null &&
        state.invoice.additionalDatasPa.length > 0
      ) {
        state.invoice.additionalDatasPa = [
          { ...state.invoice.additionalDatasPa[0], type: action.payload },
        ];
      } else {
        state.invoice.additionalDatasPa = [{ type: action.payload }];
      }
    },
    setCustomerInvoiceAdditionalDataPADocumentId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      if (
        state.invoice.additionalDatasPa !== undefined &&
        state.invoice.additionalDatasPa !== null &&
        state.invoice.additionalDatasPa.length > 0
      ) {
        state.invoice.additionalDatasPa = [
          { ...state.invoice.additionalDatasPa[0], documentId: action.payload },
        ];
      } else {
        state.invoice.additionalDatasPa = [{ documentId: action.payload }];
      }
    },
    setCustomerInvoiceAdditionalDataPADate: (
      state,
      action: PayloadAction<Date | undefined>
    ) => {
      if (
        state.invoice.additionalDatasPa !== undefined &&
        state.invoice.additionalDatasPa !== null &&
        state.invoice.additionalDatasPa.length > 0
      ) {
        state.invoice.additionalDatasPa = [
          { ...state.invoice.additionalDatasPa[0], date: action.payload },
        ];
      } else {
        state.invoice.additionalDatasPa = [{ date: action.payload }];
      }
    },
    setCustomerInvoiceAdditionalDataPACIG: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      if (
        state.invoice.additionalDatasPa !== undefined &&
        state.invoice.additionalDatasPa !== null &&
        state.invoice.additionalDatasPa.length > 0
      ) {
        state.invoice.additionalDatasPa = [
          { ...state.invoice.additionalDatasPa[0], cigCode: action.payload },
        ];
      } else {
        state.invoice.additionalDatasPa = [{ cigCode: action.payload }];
      }
    },
    setCustomerInvoiceAdditionalDataPACUP: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      if (
        state.invoice.additionalDatasPa !== undefined &&
        state.invoice.additionalDatasPa !== null &&
        state.invoice.additionalDatasPa.length > 0
      ) {
        state.invoice.additionalDatasPa = [
          { ...state.invoice.additionalDatasPa[0], cupCode: action.payload },
        ];
      } else {
        state.invoice.additionalDatasPa = [{ cupCode: action.payload }];
      }
    },
    setCustomerInvoiceAdditionalDataPAAgreementJobCode: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      if (
        state.invoice.additionalDatasPa !== undefined &&
        state.invoice.additionalDatasPa !== null &&
        state.invoice.additionalDatasPa.length > 0
      ) {
        state.invoice.additionalDatasPa = [
          {
            ...state.invoice.additionalDatasPa[0],
            agreementJobCode: action.payload,
          },
        ];
      } else {
        state.invoice.additionalDatasPa = [
          { agreementJobCode: action.payload },
        ];
      }
    },
    setCustomerInvoiceCopyrights: (state, action: PayloadAction<boolean>) => {
      state.invoice.copyrights = action.payload;
    },
    setCustomerInvoiceSportsRelated: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.invoice.sportsRelated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findCustomerInvoiceById.pending, (state) => {
        state.findByIdStatus = 'loading';
      })
      .addCase(findCustomerInvoiceById.fulfilled, (state, action) => {
        state.findByIdStatus = 'successfully';
        state.invoice = action.payload.invoice;
        state.fileList = action.payload.files;
        state.reimbursementsOfExpenses =
          action.payload.reimbursementsOfExpenses;
      })
      .addCase(findCustomerInvoiceById.rejected, (state) => {
        state.findByIdStatus = 'failed';
      });
  },
});

export const {
  setCustomerInvoiceFileList,
  setCustomerInvoiceReimbursementsOfExpenses,
  setCustomerInvoiceAteco,
  setCustomerInvoiceBankAccount,
  setCustomerInvoiceCausal,
  setCustomerInvoiceCustomer,
  setCustomerInvoiceDate,
  setCustomerInvoiceGoodServices,
  setCustomerInvoiceStatus,
  setCustomerInvoiceNotes,
  setCustomerInvoicePaymentExpiration,
  setCustomerInvoicePaymentMode,
  setCustomerInvoicePaymentTerms,
  setCustomerInvoiceSocialContribution,
  setCustomerInvoiceSocialContributionAmount,
  setCustomerInvoiceStamp,
  setCustomerInvoiceType,
  setCustomerInvoiceReimbursementOfExpensesAmount,
  setCustomerInvoiceTaxBase,
  setCustomerInvoice,
  setCustomerInvoiceDocumentType,
  setCustomerInvoiceAdditionalDataPAType,
  setCustomerInvoiceAdditionalDataPADocumentId,
  setCustomerInvoiceAdditionalDatasPa,
  setCustomerInvoiceAdditionalDataPADate,
  setCustomerInvoiceAdditionalDataPAAgreementJobCode,
  setCustomerInvoiceAdditionalDataPACIG,
  setCustomerInvoiceAdditionalDataPACUP,
  setCustomerInvoiceSocialEnasarco,
  setCustomerInvoicePdfMailLanguage,
  setCustomerInvoiceFund,
  setCustomerInvoicePreviousStatus,
  setCustomerInvoiceExternalID,
  setCustomerInvoiceNumber,
  setCustomerInvoiceWithholding,
  setCustomerInvoiceWithholdingRate,
  setCustomerInvoiceWithholdingTaxRate,
  setCustomerInvoiceWithholdingActive,
  setCustomerInvoiceWithholdingAmount,
  setCustomerInvoiceWithholdingType,
  setCustomerInvoiceWithholdingCausal,
  setCustomerInvoiceNumerationCustom,
  setCustomerInvoiceCopyrights,
  setCustomerInvoiceSportsRelated,
} = customerInvoiceSlice.actions;

export default customerInvoiceSlice.reducer;
