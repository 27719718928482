import useComponentVisible from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './foreignProvinceInfo.json';
import './foreignProvinceInfo.css';

export function ForeignProvinceInfo() {
  const componentVisible = useComponentVisible(false);

  return (
    <div>
      <div className={'position-relative'}>
        <div
          onMouseOver={() => componentVisible.setIsVisible(true)}
          onMouseOut={() => componentVisible.setIsVisible(false)}
        >
          <QuickfiscoIcon name={'info.svg'} />
        </div>
        <div
          ref={componentVisible.ref}
          className={'foreign-province-info-popup'}
        >
          {componentVisible.isVisible && (
            <div className={'foreign-province-info-popup-container pb-1'}>
              <p className={'foreign-province-info-text'}>{lang.text_1}</p>
              <p className={'foreign-province-info-text'}>{lang.text_2}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
