import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoPayment } from '../quickfiscoPayment/quickfiscoPayment';
import { ContractSignatureService } from '../../services/contractSignatureService';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { YouSignIframe } from '../youSignIframe/youSignIframe';
import { UserService } from '../../services/userService';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import {
  delItem,
  getItemWithExipiration,
  setItemWithExipiration,
} from '../../utils/localStorage';
import lang from './renewModal.json';
import './renewModal.css';

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

export function RenewModal(props: Props) {
  const { open } = props;
  const { setOpen } = props;

  const [stepIndex, setStepIndex] = useState<number>(0);
  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [signatureLink, setSignatureLink] = useState<string | undefined>(
    undefined
  );
  const [youSignSignatureRequestId, setYouSignSignatureRequestId] = useState<
    string | undefined
  >(undefined);
  const [spinnerClosing, setSpinnerClosing] = useState(false);

  const user = useAppSelector((state) => state.user.editUserRequest);

  let positionStep: React.ReactNode;

  useEffect(() => {
    if (getItemWithExipiration('renewStepIndex') === '1') {
      setStepIndex(1);
    }
  }, []);

  useEffect(() => {
    open === true && getSignatureLinkFunc();
  }, [open]);

  useEffect(() => {
    if (stepIndex === 2) {
      setSpinnerClosing(true);

      const timer = setTimeout(() => {
        delItem('renewStepIndex');
        window.location.reload();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [stepIndex, setOpen]);

  const getSignatureLinkFunc = () => {
    const contractSignatureService = new ContractSignatureService();

    setStatus('loading');

    contractSignatureService
      .getSignatureLink()
      .then((key) => {
        setSignatureLink(key);
        setStatus('successfully');
      })
      .catch((err) => {
        console.error(err);
        setStatus('failed');
      });
  };

  const updateUser = (signatureKey: string) => {
    const userService = new UserService();

    userService
      .updateMe({
        ...user,
        contractDoc: {
          ...user.contractDoc,
          youSignSignatureRequestId: signatureKey,
        },
      })
      .then(() => {
        setYouSignSignatureRequestId(signatureKey);
        setItemWithExipiration('renewStepIndex', {
          value: '1',
          expireTime: Date.now() + 1000 * 60 * 60 * 24 * 50,
        });
      })
      .catch((err) => {
        console.error(err);
        setStatus('failed');
      });
  };

  switch (stepIndex) {
    case 0:
      positionStep = (
        <>
          <div className="row mb-4">
            <div className="col-12 renew-modal-title">
              {lang.title} {new Date().getFullYear()}
            </div>
          </div>
          {signatureLink && (
            <div className="row my-3">
              <div className="col-12">
                <YouSignIframe
                  signatureLink={signatureLink}
                  onSuccessSignature={(youSignSignatureRequestId: string) =>
                    updateUser(youSignSignatureRequestId)
                  }
                />
              </div>
            </div>
          )}
          <div className="row mt-3">
            <div className="col-4 offset-8">
              <QuickfiscoButton
                type={youSignSignatureRequestId ? 'secondary' : 'disabled'}
                id="renew-modal-go-next"
                label={lang.goNext}
                onClick={() => setStepIndex(stepIndex + 1)}
              />
            </div>
          </div>
        </>
      );
      break;
    case 1:
      positionStep = (
        <QuickfiscoPayment
          isRenewal={true}
          renewalNextStep={() => {
            setSpinnerClosing(true);
            setStepIndex(stepIndex + 1);
          }}
        />
      );
      break;
    case 2:
      positionStep = (
        <div>
          {spinnerClosing && (
            <div className="text-center my-5 py-5">
              <div className="pt-5">
                <QuickfiscoSpinner />
              </div>
              <div className="renew-modal-update-profile-data mt-4 mb-5 pb-5">
                {lang.updateProfileData}
              </div>
            </div>
          )}
        </div>
      );
      break;
  }

  return (
    <QuickfiscoModal
      modalClassName={'renew-modal-component'}
      isOpen={open}
      hide={() => setOpen(false)}
      size={'lg'}
      closeButton={spinnerClosing}
    >
      <>
        <div className="renew-modal-container">
          {status === 'loading' ? (
            <div className="d-flex justify-content-center align-items-center my-5 py-5">
              <QuickfiscoSpinner />
            </div>
          ) : status === 'failed' ? (
            <div className="row mb-5">
              <div className="col-12 mt-5">
                <div className="text-center my-5">
                  <div>
                    <p className="onboarding-api-errorops-title mt-4">OPS...</p>
                    <h1 className="onboarding-api-errorfailed-title">
                      sembra che qualcosa non sia andato per il verso giusto
                    </h1>
                    <p className="onboarding-api-errorrequirements">
                      Non siamo riusciti a recuperare/salvare i dati del
                      contratto, riprova tra qualche minuto.
                      <br />
                      Se continui a riscontrare problemi scrivici a &nbsp;
                      <a
                        href={`mailto:support@quickfisco.it`}
                        target={'_blank'}
                        rel={'noreferrer'}
                      >
                        support@quickfisco.it
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>{positionStep}</div>
          )}
        </div>
      </>
    </QuickfiscoModal>
  );
}
