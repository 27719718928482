import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uiidv4 } from 'uuid';
import { ContactType, PersonType } from '../../models/contactModel';
import { InvoiceErrorSetters } from '../../models/invoiceErrorSetters';
import {
  InvoiceModel,
  InvoiceStatusType,
  InvoiceType,
  ReimbursementOfExpenseModel,
  TypeWithholding,
  WithholdingCausal,
} from '../../models/invoiceModel';
import { CategoryType, UserStatusType } from '../../models/userModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  editCustomerInvoice,
  setCustomerInvoiceSaveOrDuplicateOrEditInvoiceId,
  setCustomerInvoiceSaveOrDuplicateOrEditOperation,
  setCustomerInvoiceSaveOrDuplicateOrEditSuccessfullySaved,
} from '../../redux/slices/customerInvoiceSaveOrDuplicateOrEditSlice';
import {
  setCustomerInvoiceAdditionalDataPAAgreementJobCode,
  setCustomerInvoiceAdditionalDataPACIG,
  setCustomerInvoiceAdditionalDataPACUP,
  setCustomerInvoiceAdditionalDataPADate,
  setCustomerInvoiceAdditionalDataPADocumentId,
  setCustomerInvoiceAdditionalDataPAType,
  setCustomerInvoiceAdditionalDatasPa,
  setCustomerInvoiceAteco,
  setCustomerInvoiceBankAccount,
  setCustomerInvoiceCausal,
  setCustomerInvoiceCopyrights,
  setCustomerInvoiceCustomer,
  setCustomerInvoiceDate,
  setCustomerInvoiceDocumentType,
  setCustomerInvoiceExternalID,
  setCustomerInvoiceFileList,
  setCustomerInvoiceFund,
  setCustomerInvoiceGoodServices,
  setCustomerInvoiceNotes,
  setCustomerInvoiceNumber,
  setCustomerInvoiceNumerationCustom,
  setCustomerInvoicePaymentExpiration,
  setCustomerInvoicePaymentMode,
  setCustomerInvoicePaymentTerms,
  setCustomerInvoicePdfMailLanguage,
  setCustomerInvoicePreviousStatus,
  setCustomerInvoiceReimbursementOfExpensesAmount,
  setCustomerInvoiceReimbursementsOfExpenses,
  setCustomerInvoiceSocialContribution,
  setCustomerInvoiceSportsRelated,
  setCustomerInvoiceStamp,
  setCustomerInvoiceStatus,
  setCustomerInvoiceType,
  setCustomerInvoiceWithholdingActive,
  setCustomerInvoiceWithholdingCausal,
  setCustomerInvoiceWithholdingRate,
  setCustomerInvoiceWithholdingTaxRate,
  setCustomerInvoiceWithholdingType,
} from '../../redux/slices/customerInvoiceSlice';
import { setGlobalStatus } from '../../redux/slices/operationStatusSlice';
import uri from '../../routers/quickfiscoUri.json';
import { CustomerInvoiceService } from '../../services/customerInvoiceService';
import { UserService } from '../../services/userService';
import { SetState } from '../../types/functions';
import { Operation, PromiseStatuses } from '../../types/strings';
import { formatDateUS, resolveUndefinedDate } from '../../utils/date';
import { downloadPdf, validateFiles } from '../../utils/file';
import { setItem } from '../../utils/localStorage';
import { formatNumberIT } from '../../utils/number';
import { AdditionalDataPATypeSelect } from '../additionalDataPATypeSelect/additionalDataPATypeSelect';
import { AlreadyTransmittedCheckbox } from '../alreadyTransmittedCheckbox/alreadyTransmittedCheckbox';
import { AtecoSelect } from '../atecoSelect/atecoSelect';
import { ContactSelect } from '../contactSelect/contactSelect';
import { DocumentTypeSelect } from '../documentTypeSelect/documentTypeSelect';
import { GoodServicesInput } from '../goodServicesInput/goodServicesInput';
import { InvoiceAmount } from '../invoiceAmount/invoiceAmount';
import { InvoicePaymentList } from '../invoicePaymentList/invoicePaymentList';
import { PaymentExpirationInput } from '../paymentExpirationInput/paymentExpirationInput';
import { PaymentModeSelect } from '../paymentModeSelect/paymentModeSelect';
import { QuickfiscoBankSelect } from '../quickfiscoBankSelect/quickfiscoBankSelect';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoInputFile } from '../quickfiscoInputFile/quickfiscoInputFile';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { QuickfiscoSwitchInput } from '../quickfiscoSwitchInput/quickfiscoSwitchInput';
import { QuickfiscoTextarea } from '../quickfiscoTextarea/quickfiscoTextarea';
import { ReimbursementsOfExpense } from '../reimbursementsOfExpenses/reimbursementsOfExpense';
import { SeePreviewPdfModal } from '../seePreviewPdfModal/seePreviewPdfModal';
import { SendInvoice } from '../sendInvoice/sendInvoice';
import { SendTrialInvoiceModal } from '../sendTrialInvoiceModal/sendTrialInvoiceModal';
import { SwitchToSts } from '../switchToSts/switchToSts';
import { WithholdingCasualPayment } from '../withholdingCasualPayment/withholdingCasualPayment';
import { WithholdingTypeSelect } from '../withholdingTypeSelect/withholdingTypeSelect';
import './customerInvoiceSaveOrDuplicateOrEdit.css';
import lang from './customerInvoiceSaveOrdDuplicateOrEdit.json';
import { InvoiceStatus } from '../invoiceStatus/invoiceStatus';

export function CustomerInvoiceSaveOrDuplicateOrEdit() {
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [downloadPdfStatus, setDownloadPdfStatus] =
    useState<PromiseStatuses>('idle');
  const [sendSectionOpen, setSendSectionOpen] = useState(false);
  const [seePreviewPdfOpen, setSeePreviewPdfOpen] = useState(false);
  const [sendTrialInvoiceOpen, setSendTrialInvoiceOpen] = useState(false);

  // TODO remove
  const [errorCustomer, setErrorCustomer] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorGoodServices, setErrorGoodServices] = useState(false);
  const [errorGoodServicesInput, setErrorGoodServicesInput] = useState(false);
  const [errorPaymentMode, setErrorPaymentMode] = useState(false);
  const [errorPaymentExpiration, setErrorPaymentExpiration] = useState(false);
  const [errorFiles, setErrorFiles] = useState(false);
  const [errorReimbursementsOfExpenses, setErrorReimbursementsOfExpenses] =
    useState(false);
  const [errorDateMessage, setErrorDateMessage] = useState(lang.DateError);
  const [errorDocumentType, setErrorDocumentType] = useState(false);
  const [errorCustomNumeration, setErrorCustomNumeration] = useState(false);

  const [errorWithholdingRate, setErrorWithholdingRate] = useState(false);
  const [errorWithholdingTaxRate, setErrorWithholdingTaxRate] = useState(false);

  const service = new CustomerInvoiceService({
    setErrorCustomer,
    setErrorDate,
    setErrorGoodServicesInput,
    setErrorGoodServices,
    setErrorPaymentExpiration,
    setErrorPaymentMode,
    setErrorDateMessage,
    setErrorReimbursementsOfExpenses,
    setErrorDocumentType,
    setErrorWithholdingRate,
    setErrorWithholdingTaxRate,
    setErrorCustomNumeration,
  });
  // TODO remove

  const userState = useAppSelector((state) => state.user);
  const customerInvoiceState = useAppSelector((state) => state.customerInvoice);
  const customersListState = useAppSelector(
    (state) => state.customerList.customers.content
  );
  const customerInvoiceSaveOrDuplicateOrEditState = useAppSelector(
    (state) => state.customerInvoiceSaveOrDuplicateOrEdit
  );
  const globalStatus = useAppSelector((state) => state.operationStatus.status);
  const globalErrorMessage = useAppSelector(
    (state) => state.operationStatus.errorMessage
  );

  const user = userState.user;
  const invoice = customerInvoiceState.invoice;
  const fileList = customerInvoiceState.fileList;
  const reimbursementsOfExpenses =
    customerInvoiceState.reimbursementsOfExpenses;
  const operation = customerInvoiceSaveOrDuplicateOrEditState.operation;
  const successfullySaved =
    customerInvoiceSaveOrDuplicateOrEditState.successfullySaved;

  const [userIsNotEnabled, setUserIsNotEnabled] = useState(
    UserService.isNotEnabled(user)
  );
  const [invoiceIsEditable, setInvoiceIsEditable] = useState(
    CustomerInvoiceService.isEditable(invoice)
  );
  const [invoiceIsSendable, setInvoiceIsSendable] = useState(
    CustomerInvoiceService.isSendable(invoice)
  );

  const [Open, setIsOpen] = useState(false);

  const [showAddCustomerBox, setShowAddCustomerBox] = useState(false);

  const OpenAttribute = () => {
    setIsOpen(!Open);
  };

  const isNewCustomer = () => {
    return !customersListState.some((obj) => obj.vat === invoice.customer?.vat);
  };

  useEffect(() => {
    if (
      operation === 'duplicate' &&
      invoice.status !== InvoiceStatusType.DRAFT &&
      invoice.status !== InvoiceStatusType.ALREADY_TRANSMITTED
    ) {
      dispatch(setCustomerInvoiceStatus(InvoiceStatusType.DRAFT));
      dispatch(setCustomerInvoicePreviousStatus(undefined));
      dispatch(setCustomerInvoiceExternalID(undefined));
    }

    if (user.fund !== undefined && operation === 'save') {
      dispatch(setCustomerInvoiceFund(user.fund));
    }

    if (invoice.customer?.vat) {
      isNewCustomer();
      setShowAddCustomerBox(true);
    }
  }, [invoice, operation]);

  useEffect(() => {
    if (
      invoice.withholding?.active === true ||
      (invoice.customNumeration === true && user.customNumeration === true) ||
      invoice.copyrights === true ||
      invoice.status === InvoiceStatusType.ALREADY_TRANSMITTED
    ) {
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    setUserIsNotEnabled(UserService.isNotEnabled(user));
  }, [user.enabled, user.status]);

  useEffect(() => {
    if (
      (operation === 'edit' || operation === 'duplicate') &&
      invoice.status !== InvoiceStatusType.ALREADY_TRANSMITTED
    ) {
      setInvoiceIsEditable(CustomerInvoiceService.isEditable(invoice));
      setInvoiceIsSendable(CustomerInvoiceService.isSendable(invoice));
    }
  }, [invoice.status, operation]);

  useEffect(() => {
    if (invoice.additionalDatasPa?.[0]?.type === undefined) {
      dispatch(setCustomerInvoiceAdditionalDatasPa([]));
    }
  }, [invoice.additionalDatasPa?.[0]?.type]);

  if (userState.status === 'failed') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati.
      </div>
    );
  }

  if (userState.status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className={'row'}>
      <QuickfiscoSuccess
        message={
          invoice.type === InvoiceType.TD04
            ? lang.CreditSlipEditSuccess
            : lang.EditSuccess
        }
        active={status === 'successfully'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoSuccess
        message={
          invoice.type === InvoiceType.TD04
            ? lang.CreditSlipSaveSuccess
            : lang.SaveSuccess
        }
        active={successfullySaved}
        close={() =>
          dispatch(
            setCustomerInvoiceSaveOrDuplicateOrEditSuccessfullySaved(false)
          )
        }
      />
      <QuickfiscoError
        message={
          invoice.type === InvoiceType.TD04
            ? lang.CreditSlipSavingError
            : lang.SavingError
        }
        active={status === 'failed'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoError
        message={
          globalErrorMessage === undefined ? lang.SendError : globalErrorMessage
        }
        active={globalStatus === 'failed'}
        close={() =>
          dispatch(
            setGlobalStatus({
              status: 'idle',
            })
          )
        }
      />
      <QuickfiscoError
        message={lang.DownloadPdfError}
        active={downloadPdfStatus === 'failed'}
        close={() => setDownloadPdfStatus('idle')}
      />
      <div className={'col-12 col-xl-5 mt-4'}>
        {user.stsEnabled &&
          location.pathname !== uri.CreditSlipInvoiceSave &&
          user.importInvoicesSts?.needToImportInvoices !== true && (
            <div className={'row mb-4'}>
              <div className={'col-12'}>
                <SwitchToSts operation={operation} invoiceSign={'sts'} />
              </div>
            </div>
          )}
        {isNewCustomer() === true &&
          showAddCustomerBox &&
          invoice.customer !== undefined && (
            <div className={'row no-gutters mb-4'}>
              <div className={'col-12 no-gutters'}>
                <div className="invoice-customer-saveOrEdit-new-customer-container p-3">
                  <div className="row">
                    <div className="col-12">
                      <b> {lang.newCustomerTitle}</b>
                    </div>
                    <div className="col-12">{lang.newCustomerText}</div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <QuickfiscoButton
                        id={'invoice-customer-saveOrEdit-add-customer'}
                        type={'septarian'}
                        label={lang.newCustomerIgnoreBtn}
                        onClick={() => setShowAddCustomerBox(false)}
                      />
                    </div>
                    <div className="col-6">
                      <QuickfiscoButton
                        id={'invoice-customer-saveOrEdit-add-customer'}
                        type={'ninth'}
                        label={lang.newCustomerAddBtn}
                        onClick={() => {
                          setItem(
                            'newCustomer',
                            JSON.stringify(invoice.customer)
                          );
                          navigate({
                            pathname: uri.CustomerSave,
                            search: `?import=true`,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        <div className={'row no-gutters'}>
          <div className={'col-12 no-gutters'}>
            <ContactSelect
              type={ContactType.CUSTOMER}
              selected={invoice.customer}
              setContact={(contact) => {
                dispatch(setCustomerInvoiceCustomer(contact));
                service.validateContact(contact);
              }}
              error={errorCustomer}
              requiredField={true}
              disabled={!invoiceIsEditable}
            />
          </div>
        </div>
        {invoice.customer?.personType === PersonType.GOV && (
          <div className={'row no-gutters mt-4'}>
            <div className={'col-12 invoice-customer-saveOrEdit-container p-4'}>
              <div className="row">
                <div className="col-12">
                  <span className="invoice-customer-saveOrEdit-container-title">
                    {lang.invoiceDetails}
                    <span className="invoice-customer-saveOrEdit-container-subtitle">
                      {lang.invoiceDetailsSubtitle}
                    </span>
                  </span>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <AdditionalDataPATypeSelect
                    id={'invoice-customer-save'}
                    value={
                      invoice.additionalDatasPa &&
                      invoice.additionalDatasPa.length > 0 &&
                      invoice.additionalDatasPa?.[0].type !== null &&
                      invoice.additionalDatasPa?.[0].type !== undefined
                        ? invoice.additionalDatasPa?.[0].type
                        : undefined
                    }
                    disabled={!invoiceIsEditable}
                    onChange={(_dataType) =>
                      dispatch(
                        setCustomerInvoiceAdditionalDataPAType(_dataType)
                      )
                    }
                  />
                </div>
              </div>
              {invoice?.additionalDatasPa?.[0]?.type !== undefined &&
                invoice?.additionalDatasPa?.[0]?.type !== null &&
                invoice.additionalDatasPa.length > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <div className="row mt-4">
                        <div className="col-6">
                          <QuickfiscoInput
                            id={'invoice-customer-save-document-id'}
                            styleType={'default'}
                            type={'text'}
                            label={lang.additionalDataPADocumentId}
                            placeholder={
                              lang.additionalDataPADocumentIdPlaceholder
                            }
                            onChange={(e) =>
                              dispatch(
                                setCustomerInvoiceAdditionalDataPADocumentId(
                                  e.target.value
                                )
                              )
                            }
                            defaultValue={
                              invoice.additionalDatasPa[0].documentId
                            }
                            disabled={!invoiceIsEditable}
                          />
                        </div>
                        <div className="col-6">
                          <QuickfiscoDatePicker
                            id={'invoice-customer-save-additional-data-PA-date'}
                            label={lang.additionalDataPADate}
                            onChange={(e) =>
                              e &&
                              dispatch(
                                setCustomerInvoiceAdditionalDataPADate(
                                  resolveUndefinedDate(formatDateUS(e))
                                )
                              )
                            }
                            value={
                              invoice.additionalDatasPa[0].date &&
                              new Date(invoice.additionalDatasPa[0].date)
                            }
                            disabled={!invoiceIsEditable}
                            maxDate={new Date()}
                            styleType={'default'}
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6">
                          <QuickfiscoInput
                            id={'invoice-customer-save-document-id'}
                            styleType={'default'}
                            type={'text'}
                            label={lang.additionalDataPACIG}
                            placeholder={lang.additionalDataPACIGPlaceholder}
                            onChange={(e) =>
                              dispatch(
                                setCustomerInvoiceAdditionalDataPACIG(
                                  e.target.value
                                )
                              )
                            }
                            defaultValue={
                              invoice.additionalDatasPa[0].cigCode === null
                                ? undefined
                                : invoice.additionalDatasPa[0].cigCode
                            }
                            disabled={!invoiceIsEditable}
                          />
                        </div>
                        <div className="col-6">
                          <QuickfiscoInput
                            id={'invoice-customer-save-additional-data-PA-date'}
                            styleType={'default'}
                            type={'text'}
                            label={lang.additionalDataPACUP}
                            placeholder={lang.additionalDataPACUPPlaceholder}
                            onChange={(e) =>
                              dispatch(
                                setCustomerInvoiceAdditionalDataPACUP(
                                  e.target.value
                                )
                              )
                            }
                            defaultValue={
                              invoice.additionalDatasPa[0].cupCode === null
                                ? undefined
                                : invoice.additionalDatasPa[0].cupCode
                            }
                            disabled={!invoiceIsEditable}
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <QuickfiscoInput
                            id={'invoice-customer-save-document-id'}
                            styleType={'default'}
                            type={'text'}
                            label={lang.additionalDataPAAgreementJobCode}
                            placeholder={
                              lang.additionalDataPAAgreementJobCodePlaceholder
                            }
                            onChange={(e) =>
                              dispatch(
                                setCustomerInvoiceAdditionalDataPAAgreementJobCode(
                                  e.target.value
                                )
                              )
                            }
                            defaultValue={
                              invoice.additionalDatasPa[0].agreementJobCode ===
                              null
                                ? undefined
                                : invoice.additionalDatasPa[0].agreementJobCode
                            }
                            disabled={!invoiceIsEditable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        )}
        <div className={'row no-gutters mt-4'}>
          <div className={'col-12 invoice-customer-saveOrEdit-container p-4'}>
            <div className={'row'}>
              <QuickfiscoCheckbox
                id={'invoice-customer-save-creditNote'}
                label={lang.line3}
                fontNormal
                onChange={(e) =>
                  dispatch(
                    setCustomerInvoiceType(
                      e.target.checked ? InvoiceType.TD04 : InvoiceType.TD01
                    )
                  )
                }
                checked={invoice.type === InvoiceType.TD04}
                disabled={
                  operation === 'edit' ||
                  location.pathname === uri.CreditSlipInvoiceSave
                }
              />
            </div>
            {customerInvoiceState.invoice.ateco ===
              '85.51.00 - Corsi sportivi e ricreativi' &&
              '85.52.01 - Corsi di danza' && (
                <div className={'row'}>
                  <QuickfiscoCheckbox
                    id={'invoice-customer-save-sportsRelated'}
                    label={lang.sportsRelated}
                    fontNormal
                    onChange={(e) =>
                      dispatch(
                        setCustomerInvoiceSportsRelated(e.target.checked)
                      )
                    }
                    checked={invoice.sportsRelated}
                    disabled={!invoiceIsEditable}
                  />
                </div>
              )}
            {user &&
            user?.fund !== 'TC07' &&
            user.category !== CategoryType.ARTISAN &&
            user.category !== CategoryType.TRADER ? (
              <div className="invoice-customer-save-or-duplicate-enasarco mt-4">
                <div className={'col d-flex align-items-center'}>
                  <QuickfiscoSwitchInput
                    id={
                      'invoice-customer-save-or-duplicate-social-contribution'
                    }
                    label={lang.SocialContribution}
                    onChange={(checked) =>
                      dispatch(setCustomerInvoiceSocialContribution(checked))
                    }
                    checked={
                      invoice.socialContribution === null
                        ? undefined
                        : invoice.socialContribution
                    }
                    disabled={!invoiceIsEditable}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            {user &&
            user.atecos &&
            user.atecos.length > 1 &&
            user.atecos.some((ateco) => ateco === invoice.ateco) ? (
              <div className={'row my-4'}>
                <div className={'col-12'}>
                  <AtecoSelect
                    id={'customer-invoice-ateco-select'}
                    onChange={(_ateco) =>
                      dispatch(setCustomerInvoiceAteco(_ateco))
                    }
                    value={invoice.ateco === null ? undefined : invoice.ateco}
                    type={'own'}
                    disabled={!invoiceIsEditable}
                  />
                </div>
              </div>
            ) : (
              <div className={'row mt-4 mb-0'}>
                <div className={'col-12'}>
                  <QuickfiscoInput
                    id={'invoice-customer-save-ateco'}
                    type={'text'}
                    label={'Codice ateco'}
                    value={invoice.ateco || '-'}
                    disabled={true}
                    styleType={'default'}
                  />
                </div>
              </div>
            )}
            <div className={'row mt-2'}>
              <QuickfiscoDatePicker
                id={'invoice-customer-save-date'}
                label={lang.line2}
                onChange={(e) =>
                  e &&
                  dispatch(
                    setCustomerInvoiceDate(
                      resolveUndefinedDate(formatDateUS(e))
                    )
                  )
                }
                value={new Date(invoice.date)}
                error={errorDate}
                errorLabel={errorDateMessage}
                required={true}
                disabled={!invoiceIsEditable}
                onBlur={(e) => {
                  service.validateDate(resolveUndefinedDate(e.target.value));
                }}
                maxDate={new Date()}
                styleType={'default'}
              />
            </div>
            <div className={'row'}>
              <PaymentModeSelect
                id={'invoice-customer-save-payment-mode'}
                onChange={(paymentMode) => {
                  dispatch(setCustomerInvoicePaymentMode(paymentMode));
                  service.validatePaymentMode(paymentMode);
                }}
                value={invoice.paymentMode}
                error={errorPaymentMode}
                disabled={!invoiceIsEditable}
              />
            </div>
            <div className={'mt-3'}>
              <PaymentExpirationInput
                id={'invoice-customer-save-payment-expiration'}
                value={invoice.paymentExpiration}
                onChange={(value, valueTerms) => {
                  dispatch(setCustomerInvoicePaymentExpiration(value));
                  dispatch(setCustomerInvoicePaymentTerms(valueTerms));
                }}
                startDate={invoice.date}
                error={errorPaymentExpiration}
                invoicePaymentTerms={invoice.paymentTerms}
                disabled={!invoiceIsEditable}
                onBlur={(value) =>
                  service.validatePaymentExpiration(invoice.date, value)
                }
              />
            </div>
            {user.bankAccount &&
            user.bankAccount.some(
              (bank) => bank.name === invoice.supplier?.bankAccount.bankName
            ) ? (
              <div className={'row mt-2'}>
                <QuickfiscoBankSelect
                  id={'invoice-customer-save-bank'}
                  requiredField={true}
                  onChange={(bank) => {
                    dispatch(
                      setCustomerInvoiceBankAccount({
                        name:
                          user.bankAccount.find(
                            (bankTemp) => bankTemp.iban == bank
                          )?.name ?? '',
                        iban: bank,
                      })
                    );
                  }}
                  value={
                    operation === 'edit' ||
                    invoice.supplier?.bankAccount != undefined
                      ? {
                          name: invoice.supplier?.bankAccount.bankName || '',
                          iban: invoice.supplier?.bankAccount.bankIban || '',
                        }
                      : user.bankAccount.find(
                          (bank) => bank.default === true
                        ) || { name: '', iban: '' }
                  }
                  disabled={!invoiceIsEditable}
                />
              </div>
            ) : (
              <div className={'row mt-2'}>
                <QuickfiscoInput
                  id={'invoice-customer-save-bank'}
                  type={'text'}
                  label={'Banca'}
                  value={`${invoice.supplier?.bankAccount.bankName} - ${invoice.supplier?.bankAccount.bankIban}`}
                  disabled={true}
                  styleType={'default'}
                />
              </div>
            )}
            <div className={'row mt-4'}>
              <QuickfiscoTextarea
                id={'invoice-customer-save-notes'}
                label={lang.line8}
                onChange={(e) =>
                  dispatch(setCustomerInvoiceNotes(e.target.value))
                }
                value={invoice.notes === null ? '' : invoice.notes}
                placeholder={lang.placeholderNote}
                secondaryLabel={lang.secondaryLabel}
                disabled={!invoiceIsEditable}
                maxLength={330}
              />
            </div>
          </div>
          <div className={'row no-gutters mt-4'}>
            <div
              onClick={OpenAttribute}
              className={
                Open !== true
                  ? 'col-12 invoice-customer-saveOrEdit-container p-4'
                  : 'col-12 invoice-customer-saveOrEdit-container-close p-4'
              }
            >
              <div className="invoice-customer-attribute-open">
                <div>
                  <span className="invoice-customer-saveOrEdit-blue-title">
                    Attributi avanzati
                  </span>
                </div>
                <div>
                  {Open === false ? (
                    <QuickfiscoIcon name="show.svg"></QuickfiscoIcon>
                  ) : (
                    <QuickfiscoIcon name="show-reverse.svg"></QuickfiscoIcon>
                  )}
                </div>
              </div>
            </div>
            {Open === true && (
              <div
                className={
                  'col-12 invoice-customer-saveOrEdit-container-toup p-4'
                }
              >
                <div>
                  <div className="mb-4" style={{ marginTop: '-10px' }}>
                    <AlreadyTransmittedCheckbox
                      id="customer-invoice-already-transmitted-input"
                      value={invoice.status}
                      disabled={!invoiceIsEditable}
                      onChange={(e) => {
                        dispatch(
                          setCustomerInvoiceStatus(
                            e.target.checked
                              ? InvoiceStatusType.ALREADY_TRANSMITTED
                              : InvoiceStatusType.DRAFT
                          )
                        );
                      }}
                    />
                  </div>
                  {invoice.type !== InvoiceType.TD04 && (
                    <div className="mb-4" style={{ marginTop: '-10px' }}>
                      <DocumentTypeSelect
                        id="customer-invoice-document-type-select"
                        disabled={!invoiceIsEditable}
                        invoiceSign={'customer'}
                        onChange={(_type) =>
                          dispatch(setCustomerInvoiceDocumentType(_type))
                        }
                        value={invoice.type}
                        error={errorDocumentType}
                        colorLabel="quickfisco-input-label-blue"
                      />
                    </div>
                  )}
                  <div className="invoice-customer-save-or-duplicate-withholding-switch">
                    <QuickfiscoSwitchInput
                      id="withholding"
                      disabled={!invoiceIsEditable}
                      label="Ritenuta"
                      onChange={(checked) => {
                        dispatch(setCustomerInvoiceWithholdingActive(checked));
                      }}
                      checked={invoice.withholding?.active}
                      colorLabel="quickfisco-switch-input-label-blue"
                    />
                  </div>
                  {invoice.withholding?.active && (
                    <>
                      <div className="mt-2">
                        <WithholdingTypeSelect
                          id="customer-invoice-document-type-select"
                          disabled={!invoiceIsEditable}
                          invoiceSign={'customer'}
                          onChange={(withholdingType) =>
                            withholdingType &&
                            dispatch(
                              setCustomerInvoiceWithholdingType(withholdingType)
                            )
                          }
                          value={
                            invoice.withholding.type
                              ? invoice.withholding.type
                              : TypeWithholding.RT03
                          }
                        />
                      </div>
                      <div className="row">
                        <div className="mt-2 col-6">
                          <NumericFormat
                            key={'invoice-customer-causal-input'}
                            suffix="%"
                            defaultValue={
                              invoice.withholding.taxRate
                                ? invoice.withholding.taxRate
                                : ''
                            }
                            decimalSeparator=","
                            decimalScale={2}
                            customInput={QuickfiscoInput}
                            onValueChange={(e) => {
                              dispatch(
                                setCustomerInvoiceWithholdingTaxRate(
                                  e.floatValue
                                )
                              );
                              service.validateWithholdingTaxRate(
                                invoice.withholding?.active,
                                e.floatValue
                              );
                            }}
                            styleType={'default'}
                            id="invoice-customer-causal-input"
                            label={'Aliquota ritenuta'}
                            placeholder="0%"
                            error={errorWithholdingTaxRate}
                            errorLabel="Inserisci un valore"
                            value={invoice.withholding.taxRate}
                            disabled={!invoiceIsEditable}
                          />
                        </div>
                        <div className="mt-2 col-6">
                          <NumericFormat
                            key={'invoice-customer-causal-input'}
                            suffix="%"
                            defaultValue={
                              invoice.withholding.rate
                                ? invoice.withholding.rate
                                : 100
                            }
                            value={invoice.withholding.rate}
                            decimalSeparator=","
                            decimalScale={2}
                            customInput={QuickfiscoInput}
                            onValueChange={(e) => {
                              dispatch(
                                setCustomerInvoiceWithholdingRate(e.floatValue)
                              );
                              service.validateWithholdingRate(
                                invoice.withholding?.active,
                                e.floatValue
                              );
                            }}
                            styleType={'default'}
                            id="invoice-customer-causal-input"
                            label={"% sull'imponibile"}
                            placeholder="100%"
                            error={errorWithholdingRate}
                            errorLabel="Inserisci un valore"
                            disabled={!invoiceIsEditable}
                          />
                        </div>
                      </div>
                      <div>
                        <WithholdingCasualPayment
                          id="customer-invoice-document-type-select"
                          disabled={!invoiceIsEditable}
                          invoiceSign={'customer'}
                          onChange={(withholdingCausal) =>
                            withholdingCausal &&
                            dispatch(
                              setCustomerInvoiceWithholdingCausal(
                                withholdingCausal
                              )
                            )
                          }
                          value={
                            invoice.withholding.causal
                              ? invoice.withholding.causal
                              : WithholdingCausal.ZO
                          }
                        />
                      </div>
                    </>
                  )}
                  <div className="d-flex flex-row justify-content-between">
                    {user.customNumeration && (
                      <div className=" mt-3 invoice-customer-save-or-duplicate-customer-numeration-switch ">
                        <QuickfiscoSwitchInput
                          id="custom-numeration"
                          disabled={!invoiceIsEditable}
                          label="Numerazione Custom"
                          onChange={(checked) => {
                            dispatch(
                              setCustomerInvoiceNumerationCustom(checked)
                            );
                          }}
                          checked={invoice.customNumeration}
                          colorLabel="quickfisco-switch-input-label-blue"
                        />
                      </div>
                    )}
                    {user.customNumeration && invoice.customNumeration && (
                      <div className=" mt-3 w-50">
                        <QuickfiscoInput
                          id={'invoice-customer-save-customer-number'}
                          type={'text'}
                          value={invoice.number}
                          onChange={(e) => {
                            dispatch(setCustomerInvoiceNumber(e.target.value));
                            errorCustomNumeration &&
                              service.validateCustomNumeration(e.target.value);
                          }}
                          disabled={false}
                          error={errorCustomNumeration}
                          errorLabel="Devi inserire almeno una lettera e almeno un numero"
                          placeholder="Es. AA237"
                          styleType={'default'}
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <div className=" mt-3 invoice-customer-save-or-duplicate-customer-numeration-switch ">
                      <QuickfiscoSwitchInput
                        id="copyrights"
                        disabled={!invoiceIsEditable}
                        label="Diritti d'autore"
                        onChange={(checked) => {
                          dispatch(setCustomerInvoiceCopyrights(checked));
                        }}
                        checked={invoice.copyrights}
                        colorLabel="quickfisco-switch-input-label-blue"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {operation === 'edit' &&
          invoice.type !== InvoiceType.TD04 &&
          invoice.type !== InvoiceType.STS &&
          (invoice.status === InvoiceStatusType.ACCEPTED ||
            invoice.status === InvoiceStatusType.PAID ||
            invoice.status === InvoiceStatusType.ALREADY_TRANSMITTED ||
            invoice.status === InvoiceStatusType.DUE_TERMS ||
            invoice.status === InvoiceStatusType.NOT_RECEIVED ||
            invoice.status === InvoiceStatusType.DELIVERED ||
            invoice.status === InvoiceStatusType.REVERSED) && (
            <div className={'row mt-4'}>
              <div className={'col-12'}>
                <InvoicePaymentList
                  invoiceSign={'customer'}
                  invoice={invoice}
                  user={user}
                />
              </div>
            </div>
          )}
      </div>
      <div className={'col-12 col-xl-7 mt-4'}>
        <div className={'row no-gutters'}>
          <div className={'col-12 invoice-customer-saveOrEdit-container p-4'}>
            <div className={'row'}>
              <div className={'col-12'}>
                <QuickfiscoInput
                  id="invoice-customer-causal-input"
                  type={'text'}
                  label={'Oggetto/Causale Fattura'}
                  value={invoice.causal ? invoice.causal : ''}
                  placeholder={'La causale deve essere lunga max 200 caratteri'}
                  maxLength={200}
                  disabled={!invoiceIsEditable}
                  onChange={(e) =>
                    dispatch(setCustomerInvoiceCausal(e.target.value))
                  }
                  styleType={'default'}
                />
              </div>
            </div>
            <div className={'row mt-4'}>
              <div className={'col-12'}>
                <GoodServicesInput
                  goodServices={
                    invoice.goodServices !== undefined &&
                    invoice.goodServices !== null &&
                    invoice.goodServices.length > 0
                      ? invoice.goodServices
                      : [
                          {
                            key: uiidv4(),
                            quantity: 1,
                            amount: 0,
                          },
                        ]
                  }
                  setGoodServices={(goodServices) =>
                    dispatch(setCustomerInvoiceGoodServices(goodServices))
                  }
                  invoiceSign={'customer'}
                  error={errorGoodServices || errorGoodServicesInput}
                  errorLabel={
                    errorGoodServices
                      ? lang.GoodServiceError
                      : lang.GoodServiceInputError
                  }
                  disabled={!invoiceIsEditable}
                />
              </div>
            </div>
            <div className={'row mt-5'}>
              <div className={'col-12'}>
                <ReimbursementsOfExpense
                  customer={invoice.customer}
                  error={errorReimbursementsOfExpenses}
                  reimbursementsOfExpenses={reimbursementsOfExpenses}
                  addReimbursementOfExpense={(
                    newReimbursementOfExpenses: ReimbursementOfExpenseModel
                  ) =>
                    addReimbursementOfExpense(
                      newReimbursementOfExpenses,
                      reimbursementsOfExpenses,
                      {
                        setErrorCustomer,
                        setErrorDate,
                        setErrorGoodServicesInput,
                        setErrorGoodServices,
                        setErrorPaymentExpiration,
                        setErrorPaymentMode,
                        setErrorDateMessage,
                        setErrorReimbursementsOfExpenses,
                        setErrorDocumentType,
                        setErrorCustomNumeration,
                      },
                      dispatch,
                      invoice
                    )
                  }
                  deleteReimbursementOfExpense={(index: number) =>
                    deleteReimbursementOfExpense(
                      reimbursementsOfExpenses,
                      index,
                      dispatch,
                      invoice
                    )
                  }
                />
              </div>
            </div>
            <div className={'row mt-5'}>
              <div className={'col-12'}>
                <div className={'invoice-customer-saveOrEdit-line'} />
              </div>
            </div>
            <div className={'mt-4 d-flex align-items-center'}>
              <QuickfiscoSwitchInput
                id={'invoice-customer-save-or-duplicate-stamp'}
                disabled={!invoiceIsEditable}
                label={lang.Stamp}
                onChange={(checked) =>
                  dispatch(
                    setCustomerInvoiceStamp(
                      checked &&
                        invoice.taxBase !== null &&
                        invoice.taxBase !== undefined &&
                        invoice.taxBase >
                          CustomerInvoiceService.stampActivationThreshold
                    )
                  )
                }
                checked={invoice.stamp === true}
              />
              <div className={'invoice-customer-saveOrEdit-title ms-5'}>
                {formatNumberIT(CustomerInvoiceService.stampAmount)}€
              </div>
            </div>
            <div className={'row mt-3'}>
              <div className={'col-12'}>
                <div className={'invoice-customer-saveOrEdit-line'} />
              </div>
            </div>
            <div className={'mt-3'}>
              <InvoiceAmount invoice={invoice} invoiceSign="customer" />
            </div>
            <div className={'row mt-5'}>
              <div className={'col-12'}>
                <QuickfiscoInputFile
                  preview={true}
                  multiple={true}
                  maxHeight={'300px'}
                  fileList={fileList}
                  disabled={!invoiceIsEditable}
                  setFileList={(_fileList) => {
                    dispatch(setCustomerInvoiceFileList(_fileList));
                  }}
                  error={errorFiles}
                  errorLabel={lang.filesError}
                />
              </div>
            </div>
            <div className={'row mt-4 align-items-center'}>
              <div className={'col-xl-3 col-12'}>
                <QuickfiscoCheckbox
                  id={'invoice-customer-save-or-duplicate-pdf-mail-language'}
                  disabled={!invoiceIsEditable}
                  label={lang.pdfMailLanguage}
                  onChange={(e) => {
                    if (e.target.checked) {
                      dispatch(setCustomerInvoicePdfMailLanguage('EN'));
                    } else {
                      dispatch(setCustomerInvoicePdfMailLanguage(null));
                    }
                  }}
                  checked={invoice.language === 'EN'}
                />
              </div>
              <div className={'col-xl-3 col-4'}>
                {status === 'loading' ? (
                  <div
                    className={
                      'w-100 d-flex justify-content-center align-items-center'
                    }
                  >
                    <QuickfiscoSpinner />
                  </div>
                ) : (
                  <QuickfiscoButton
                    id={'invoice-customer-save-button'}
                    label={lang.line14}
                    onClick={() =>
                      saveOrEdit(
                        dispatch,
                        operation,
                        invoice,
                        setStatus,
                        {
                          setErrorDate,
                          setErrorCustomer,
                          setErrorPaymentMode,
                          setErrorPaymentExpiration,
                          setErrorGoodServices,
                          setErrorGoodServicesInput,
                          setErrorDateMessage,
                          setErrorReimbursementsOfExpenses,
                          setErrorDocumentType,
                          setErrorCustomNumeration,
                          setErrorWithholdingRate,
                          setErrorWithholdingTaxRate,
                        },
                        fileList,
                        setErrorFiles,
                        reimbursementsOfExpenses
                      )
                    }
                    type={
                      userIsNotEnabled || !invoiceIsEditable
                        ? 'disabled'
                        : 'secondary'
                    }
                  />
                )}
              </div>
              <div className={'col-xl-3 col-4'}>
                <QuickfiscoButton
                  id={'invoice-customer-pdf-button'}
                  label={lang.line13}
                  type={
                    operation !== 'edit' || userIsNotEnabled
                      ? 'disabled'
                      : 'primary'
                  }
                  onClick={() => setSeePreviewPdfOpen(true)}
                />
              </div>
              <div className={'col-xl-3 col-4'}>
                <QuickfiscoButton
                  id={'invoice-customer-send-button'}
                  label={lang.line15}
                  type={
                    operation !== 'edit' ||
                    userIsNotEnabled ||
                    !invoiceIsSendable
                      ? 'disabled'
                      : 'primary'
                  }
                  onClick={() => {
                    if (user.status === UserStatusType.CONFIRMED) {
                      setSendTrialInvoiceOpen(true);
                    } else {
                      setSendSectionOpen(true);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SeePreviewPdfModal
        open={seePreviewPdfOpen}
        setOpen={(open: boolean) => setSeePreviewPdfOpen(open)}
        invoiceSign={'customer'}
        onClick={() => {
          if (invoice.type === undefined) {
            return;
          }

          getPdf(
            setDownloadPdfStatus,
            invoice.type,
            invoice.id,
            invoice.number,
            invoice.customer?.companyName
          );
        }}
        downloadPdfStatus={downloadPdfStatus}
      />
      <SendInvoice
        open={sendSectionOpen}
        setOpen={(open: boolean) => setSendSectionOpen(open)}
        loading={globalStatus === 'loading'}
        onClick={() =>
          send(dispatch, navigate, setSendSectionOpen, invoice.id, invoice.type)
        }
      />
      <SendTrialInvoiceModal
        open={sendTrialInvoiceOpen}
        setOpen={(open: boolean) => setSendTrialInvoiceOpen(open)}
        type={invoice.type === InvoiceType.TD04 ? 'creditSlip' : 'invoice'}
      />
    </div>
  );
}

function saveOrEdit(
  dispatch: Function,
  operation: Operation,
  invoice: InvoiceModel,
  setStatus: SetState<PromiseStatuses>,
  errorSetters: InvoiceErrorSetters,
  fileList: File[],
  setErrorFiles: SetState<boolean>,
  reimbursementsOfExpenses: ReimbursementOfExpenseModel[]
): void {
  setErrorFiles(false);

  const service = new CustomerInvoiceService(errorSetters);

  if (!service.validate(invoice, 'customer')) {
    return;
  }

  if (!validateFiles(fileList)) {
    setErrorFiles(true);
    return;
  }

  if (operation === 'duplicate' || operation === 'save') {
    setStatus('loading');
    service
      .save({ ...invoice }, [...fileList], [...reimbursementsOfExpenses])
      .then((id) => {
        dispatch(setCustomerInvoiceSaveOrDuplicateOrEditInvoiceId(id));
        dispatch(setCustomerInvoiceSaveOrDuplicateOrEditOperation('edit'));
        dispatch(
          setCustomerInvoiceSaveOrDuplicateOrEditSuccessfullySaved(true)
        );
      })
      .catch((err) => handleSaveOrEditError(err, setStatus, errorSetters));
  } else {
    dispatch(
      editCustomerInvoice({
        invoice: { ...invoice },
        fileList: [...fileList],
        reimbursementsOfExpenses: [...reimbursementsOfExpenses],
        errorSetters: errorSetters,
      })
    );
    setStatus('successfully');
  }
}

function handleSaveOrEditError(
  err: any,
  setStatus: SetState<PromiseStatuses>,
  errorSetters: InvoiceErrorSetters
): void {
  err.json().then((data: any) => {
    if (data.message === 'error.invalid-date') {
      errorSetters.setErrorDateMessage &&
        errorSetters.setErrorDateMessage(
          'Esiste già una fattura con data successiva ma numero inferiore.'
        );
      errorSetters.setErrorDate && errorSetters.setErrorDate(true);
    }

    if (data.message === 'error.invalid-withholding-taxRate') {
      errorSetters.setErrorWithholdingTaxRate &&
        errorSetters.setErrorWithholdingTaxRate(true);
    }

    if (data.message === 'error.invalid-invoice-numeration') {
      errorSetters.setErrorCustomNumeration &&
        errorSetters.setErrorCustomNumeration(true);
    }

    if (data.message === 'error.invalid-withholding-rate') {
      errorSetters.setErrorWithholdingRate &&
        errorSetters.setErrorWithholdingRate(true);
    }
  });

  setStatus('failed');

  console.error(err);
}

function send(
  dispatch: Function,
  navigate: NavigateFunction,
  setModalOpen: SetState<boolean>,
  invoiceId?: string,
  invoiceType?: InvoiceType
): void {
  if (!invoiceId) {
    return;
  }

  const service = new CustomerInvoiceService();

  dispatch(
    setGlobalStatus({
      status: 'loading',
    })
  );
  service
    .send(invoiceId)
    .then(() => {
      dispatch(
        setGlobalStatus({
          status: 'successfully',
        })
      );
      if (invoiceType !== InvoiceType.TD04) {
        navigate(uri.CustomerInvoiceList);
      } else {
        navigate(uri.CreditSlipInvoiceList);
      }
    })
    .catch((err) => {
      err.json().then((data: any) => {
        if (data.message === 'error.invalid-date') {
          dispatch(
            setGlobalStatus({
              status: 'failed',
              errorMessage: lang.invalidInvoiceError,
            })
          );
        }
      });
      dispatch(
        setGlobalStatus({
          status: 'failed',
          errorMessage:
            "Si è verificato un errore durante l'invio della fattura",
        })
      );
      setModalOpen(false);
      console.error(err);
    });
}

function getPdf(
  setStatus: SetState<PromiseStatuses>,
  invoiceType: InvoiceType,
  invoiceId?: string,
  invoiceNumber?: string,
  invoiceCompanyName?: string
): void {
  if (!invoiceId) {
    return;
  }

  const service = new CustomerInvoiceService();

  setStatus('loading');
  service
    .getPdf(invoiceId)
    .then((data) => {
      setStatus('idle');
      downloadPdf(
        CustomerInvoiceService.getPdfName(
          'customer',
          invoiceType,
          invoiceNumber,
          invoiceCompanyName
        ),
        data,
        'application/pdf'
      );
    })
    .catch((err) => {
      setStatus('failed');
      console.error(err);
    });
}

function addReimbursementOfExpense(
  newReimbursementOfExpenses: ReimbursementOfExpenseModel,
  reimbursementOfExpenses: ReimbursementOfExpenseModel[],
  errorSetters: InvoiceErrorSetters,
  dispatch: Function,
  invoice: InvoiceModel
): boolean {
  const customerInvoiceService = new CustomerInvoiceService(errorSetters);

  if (
    !customerInvoiceService.validateReimbursementsOfExpenses([
      newReimbursementOfExpenses,
    ])
  ) {
    return false;
  }

  const newReimbursementsOfExpenses = [...reimbursementOfExpenses];

  newReimbursementsOfExpenses.push({
    key: newReimbursementOfExpenses.key,
    description: newReimbursementOfExpenses.description,
    amount: newReimbursementOfExpenses.amount,
    file: newReimbursementOfExpenses.file,
  });

  let newReimbursementOfExpensesAmount = 0;
  let currentReimbursementOfExpensesAmount = 0;

  if (newReimbursementOfExpenses.amount) {
    newReimbursementOfExpensesAmount = newReimbursementOfExpenses.amount;
  }
  if (invoice.reimbursementsOfExpensesAmount) {
    currentReimbursementOfExpensesAmount =
      invoice.reimbursementsOfExpensesAmount;
  }

  dispatch(
    setCustomerInvoiceReimbursementsOfExpenses([...newReimbursementsOfExpenses])
  );

  dispatch(
    setCustomerInvoiceReimbursementOfExpensesAmount(
      newReimbursementOfExpensesAmount + currentReimbursementOfExpensesAmount
    )
  );

  return true;
}

function deleteReimbursementOfExpense(
  reimbursementsOfExpenses: ReimbursementOfExpenseModel[],
  index: number,
  dispatch: Function,
  invoice: InvoiceModel
): void {
  let newReimbursementOfExpenseAmount = 0;
  let currentReimbursementsOfExpensesAmount = 0;

  if (reimbursementsOfExpenses[index].amount) {
    newReimbursementOfExpenseAmount = Number(
      reimbursementsOfExpenses[index].amount
    );
  }
  if (invoice.reimbursementsOfExpensesAmount) {
    currentReimbursementsOfExpensesAmount =
      invoice.reimbursementsOfExpensesAmount;
  }

  const newReimbursementsOfExpenses = reimbursementsOfExpenses
    .slice(0, index)
    .concat(reimbursementsOfExpenses.slice(index + 1));

  dispatch(
    setCustomerInvoiceReimbursementsOfExpenses([...newReimbursementsOfExpenses])
  );

  dispatch(
    setCustomerInvoiceReimbursementOfExpensesAmount(
      currentReimbursementsOfExpensesAmount - newReimbursementOfExpenseAmount
    )
  );
}
