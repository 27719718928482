import { InvoiceStatusType } from '../../models/invoiceModel';
import { InvoiceSign } from '../../types/strings';
import './invoicePaymentStatus.css';
import lang from './invoicePaymentStatus.json';

interface Props {
  status?: InvoiceStatusType;
  partialPaid?: boolean;
  invoiceSign?: InvoiceSign;
}

export function InvoicePaymentStatus(props: Props) {
  const { status } = props;
  const { partialPaid } = props;
  const { invoiceSign } = props;

  if (status === InvoiceStatusType.PAID) {
    return (
      <div
        className={'invoice-payment-status-info invoice-payment-status-paid'}
      >
        {invoiceSign === 'supplier' ? lang.supplierPaid : lang.paid}
      </div>
    );
  }

  if (status === InvoiceStatusType.REVERSED) {
    return (
      <div
        className={'invoice-payment-status-info invoice-payment-status-expired'}
      >
        {lang.reversed}
      </div>
    );
  }

  if (partialPaid) {
    return (
      <div
        className={'invoice-payment-status-info invoice-payment-status-to-pay'}
      >
        {invoiceSign === 'supplier'
          ? lang.supplierPartial_paid
          : lang.partial_paid}
      </div>
    );
  }

  return (
    <div
      className={'invoice-payment-status-info invoice-payment-status-to-pay'}
    >
      {invoiceSign === 'supplier' ? lang.supplierToBePaid : lang.toBePaid}
    </div>
  );
}
